import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import appointmentService from "../../../utils/api/v1/appointmentService";
import styled from "styled-components";
import { navigate, Link, PageProps } from "gatsby";
import _ from "lodash";
import labService from "../../../utils/api/v1/labService";

// typings
import { DetailedAppointment } from "../../../utils/interfaces/Appointment";
import { baseUser } from "../../../utils/interfaces/User";
import { Laboratory } from "../../../utils/interfaces/Laboratory";

// components
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import Bold from "../../../components/Typography/Bold";
import CartMaker from "../../../components/Appointments/CartMaker";
import Button from "@material-ui/core/Button";
import LoadingError from "../../../components/Loaders/LoadingError";
import Swal from "sweetalert2";
import Paper from "@material-ui/core/Paper";
import CancelAppointment from "../../../components/Appointments/Cancel/CancelAppointment";
import {
  ContentWrapper,
  Content,
} from "../../../components/Appointments/Styles/Content";
import { AppointmentPatient } from "../../../utils/interfaces/AppointmentPatient";

const Wrapper = styled(Paper)`
  padding: 4rem;
`;

const TitleWrapper = styled.div`
  text-align: center;
  width: 100%;

  h2 {
    margin-bottom: 20px;
  }
`;

interface Event {
  appointment: string;
  created_at: string;
  id: string;
  owner: string | null;
  tag: string;
}

interface AppointmentDetailProps {
  id: string;
  location: PageProps["location"];
}

const AppointmentDetail = ({
  id,
  location,
}: AppointmentDetailProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Object>({});
  const [patients, setPatients] = useState<Array<AppointmentPatient>>([]);
  const [events, setEvents] = useState<Array<Event>>([]);
  const [releasingBlock, setReleasingBlock] = useState(false);
  // @ts-ignore
  const [appointmentData, setAppointmentData] = useState<DetailedAppointment>({
    patient: baseUser,
    patients: [],
    timeline_events: [],
    timeline_events_tags: [],
    services: [],
  });
  const [labs, setLabs] = useState<Laboratory[]>([]);
  const [paramsFromUrl, setParamsFromUrl] = useState<any>({});
  const cancelComponentRef = useRef<any>(null);

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const appointment = await appointmentService.fetchDashboardAppointment(
        id
      );
      updateAppointmentData(appointment.data.data);
      setEvents(appointment.data.data.timeline_events);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const releaseBlock = async () => {
    setReleasingBlock(true);
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estás seguro de que quieres liberar este horario?",
        confirmButtonText: "Si, liberar",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then(async (res) => {
        if (res.value) {
          const releseBlockRes = await appointmentService.releaseBlock(id);
          if (releseBlockRes) {
            Swal.fire({
              icon: "success",
              title: "Horario liberado para el HT",
            });
            fetchData();
          }
        }
      });

      setReleasingBlock(false);
    } catch (err) {
      console.log(err.message);
      setReleasingBlock(false);
    }
  };

  const fetchPatients = async (): Promise<void> => {
    try {
      const patientsData = await appointmentService.getPatients(id);
      setPatients(patientsData.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const fecthLabs = async (): Promise<void> => {
    try {
      const labsData = await labService.fetchLabs(1000);
      setLabs(labsData.data.results);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const updateAppointmentData = (appointment: DetailedAppointment) => {
    const timeline_events_tags = appointment.timeline_events.map(
      (val) => val.tag
    );
    appointment.timeline_events_tags = timeline_events_tags;
    setAppointmentData(appointment);
  };

  useEffect((): void => {
    // TODO: Each of these should be handled in its own place so
    // instead of the whole page loading,
    // one of the squares will be loading
    fetchData();
    fetchPatients();
    fecthLabs();
  }, []);

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const switchModeParam = params.get("mode");
    setParamsFromUrl({ sdkMode: switchModeParam === "sdk" });
  }, []);

  const displayableEndHour = () => {
    if (appointmentData.local_work_period_max_lateness) {
      return appointmentData.local_work_period_max_lateness.match(
        /(\d{2}:\d{2})/
      )?.[0];
    }
  };

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      <CancelAppointment
        id={appointmentData.id}
        ref={cancelComponentRef}
        updateAppointmentData={updateAppointmentData}
        setError={setError}
      />
      {!loading && (
        <Wrapper variant="outlined">
          <TitleWrapper>
            <h1>Cita a través de orden médica</h1>
          </TitleWrapper>
          <ContentWrapper>
            <Content>
              {appointmentData.patients.length > 0 && (
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  onClick={() => {
                    navigate(`/appointment/${id}`);
                  }}
                >
                  &nbsp;Ir al detalle de esta cita&nbsp;
                </Button>
              )}
              <p>
                <Bold>Paciente principal: </Bold>
                {appointmentData.patient_full_name || "Sin pacientes asignados"}
              </p>
              <p>
                <Bold>Estado: </Bold>
                {appointmentData.status === "scheduled"
                  ? "Sin confirmar"
                  : "confirmada"}
              </p>
              <p>
                <Bold>HT: </Bold>
                {appointmentData?.nurse?.user?.full_name || "Sin HT asignado"}
              </p>
              <p>
                <Bold>Hora tentativa: </Bold>
                {appointmentData.displayable_begin_date} -{" "}
                {displayableEndHour()}
              </p>
              <p>
                <Bold>¿Hora tomada?: </Bold>
                {appointmentData.block_taken ? "Sí" : "No"}
              </p>
              <p>
                <Bold>Salessource: </Bold>
                {appointmentData.sales_source}
              </p>
              <Button
                variant="contained"
                color="primary"
                disabled={!appointmentData.block_taken || releasingBlock}
                onClick={releaseBlock}
              >
                Liberar hora
              </Button>
            </Content>
            <Content>
              {appointmentData.sales_source != "nuevamasvida" ? (
                <CartMaker
                  appointmentId={id}
                  locale={appointmentData.country}
                  comuna={appointmentData.comuna}
                  salesSource={appointmentData.sales_source}
                  defaultParams={paramsFromUrl}
                />
              ) : (
                <div style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "22px" }}>
                    Esta cita fue creada por flujo Nueva Masvida, para continuar
                    debes <br />
                    <Link to={`/offline-appointment/assisted-schedule/`}>
                      Ir al agendamiento asistido
                    </Link>
                  </span>
                </div>
              )}
            </Content>
          </ContentWrapper>
        </Wrapper>
      )}
    </PrivateRoute>
  );
};

export default AppointmentDetail;
